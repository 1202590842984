import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    module: 'pedidos-proveedores',
    loading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async fetchPedidosProveedores({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getAll(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchPedidoProveedor({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getById(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addPedidoProveedor({ commit, state }, pedidoData) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return await controller.create(pedidoData)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deletePedidoProveedor({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.deleteById(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async sendPedidoProveedor({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.sendPedidoProveedor(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async printPedidoProveedor({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.printPedidoProveedor(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return res
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async checkIfPedidoExistsForProveedor({ commit, state }, payload) {
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.checkIfPedidoExistsForProveedor(payload)
      } catch {
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
